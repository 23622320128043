.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
}

@keyframes marquee {
  0% { transform: translateX(20%); }
  100% { transform: translateX(-100%); }
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  flex: 1;
}

.marquee-content {
  display: inline-block;
  /* padding-left: 100%; */
  animation: marquee 20s linear infinite;
}




